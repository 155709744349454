.html--container-lottie{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.full--screen--container{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    max-width: 100%;
    background-color: black;
}
  
.lottie-root-container{
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
    overflow: hidden;
}